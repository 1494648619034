import { clickOutside, timeout } from 'widgets/toolbox/util';
import { disableScroll, enableScroll } from 'widgets/toolbox/scroll';
import viewtype from 'widgets/toolbox/viewtype';
import SwipeEvent from './swipeEvent';
const ESCAPE_CODE = 27;

/**
 * @description Base button implementation
 *
 *  Available options:
 * data-body-overflow="{Boolean}" - allows to disable body overflow class. if set data-body-overflow="true"
 *
 */
export default class Modal extends SwipeEvent {
    prefs() {
        return {
            classesGlobalOpened: 'modal-open',
            classesGlobalExtra: '',
            classesShow: 'show',
            sizeGuideRedesign: 'size-guide-redesign',
            sizeGuideRedesignClose: 'size-guide-redesign-close',
            refContainer: 'container',
            refContent: 'container',
            clickOutSide: true,
            bodyOverflow: false,
            closeByEscape: true,
            ...super.prefs()
        };
    }
    init() {
        super.init();
        this.isTouch = viewtype.isTouchDevice();
        if (this.prefs().openEvent) {
            this.eventBus().on(this.prefs().openEvent, 'showModal');
        }
        if (this.prefs().closeEvent) {
            this.eventBus().on(this.prefs().closeEvent, 'closeModal');
        }
        if (this.isTouch) {
            this.ev('swipedown', (el, e) => this.swipedown(e), null, false);
        }

    }
    swipedown(event) {
        if (event && event.detail && event.detail.srcElement && $(event.detail.srcElement).closest('.modal-header').length > 0) {
            this.closeModal();
        }
    }
    /**
     *
     * @param {any} templateData data to be rendered in template
     * @param {boolean} [preventDefault] optional to prevent the default event
     * @param {function} [callback] optional callback
     */
    showModal(templateData, preventDefault = true, callback) {
        const container = this.ref(this.prefs().refContainer);
        if (templateData) {
            this.render(void 0, templateData, container);
        }

        const modalEl = container.els[0];
        this.isSizeGuideRedesign = templateData?.product?.isSizeGuideRedesign && templateData?.product?.isSizeGuideRedesignTemplate;

        if (this.isSizeGuideRedesign) {
            container.removeClass('modal-dialog');
            modalEl.style.visibility = 'hidden';
            setTimeout(() => {
                modalEl.style.visibility = 'visible';
                container.addClass(this.prefs().sizeGuideRedesign);
                modalEl.offsetParent.style.paddingBottom = '0px';
            }, 50);
        }

        container.addClass(this.prefs().classesShow);

        if (typeof callback === 'function') {
            callback(this);
        }

        this.cleanUpListener();
        if (this.prefs().clickOutSide) {
            timeout(() => {
                const content = this.ref(this.prefs().refContent);
                this.listener = clickOutside(content, () => {
                    this.cancel();
                    return false;
                }, preventDefault);
                this.onDestroy(this.listener);
            }, 0);
        }

        if (this.prefs().closeByEscape) {
            this.escHandler = this.ev('keydown', (_, event) => {
                if (event.keyCode === ESCAPE_CODE) {
                    event.preventDefault();
                    this.cancel();
                }
            }, window, false).pop();
        }

        this.show();
        if (!this.prefs().bodyOverflow) {
            disableScroll(true);
            this.ref('html').addClass(this.prefs().classesGlobalOpened +
                (this.prefs().classesGlobalExtra ? ' ' + this.prefs().classesGlobalExtra : ''));
        }
    }
    next(event) {
        this.openSlide(event, 'modal.next');
    }
    prev(event) {
        this.openSlide(event, 'modal.prev');
    }
    openSlide(event, evSubject) {
        if (event.els && event.els.length) {
            this.eventBus().emit(evSubject, event.els[0].getAttribute('data-elem-id'));
        }
    }

    isMobileWidth() {
        return window.matchMedia('(max-width: 450px)').matches;
    }

    closeModal() {
        if (this.isSizeGuideRedesign && this.isMobileWidth()) {
            const container = this.ref(this.prefs().refContainer);
            container.addClass(this.prefs().sizeGuideRedesignClose);
            setTimeout(() => {
                this.hide();
                container.removeClass(this.prefs().sizeGuideRedesign);
                container.removeClass(this.prefs().sizeGuideRedesignClose);
                container.removeClass(this.prefs().classesShow);
                enableScroll();
                this.ref('html').removeClass(this.prefs().classesGlobalOpened +
                (this.prefs().classesGlobalExtra ? ' ' + this.prefs().classesGlobalExtra : ''));
                this.isSizeGuideRedesign = false;
            }, 500);
            this.cleanUpListener();
        } else {
            this.ref(this.prefs().refContainer).removeClass(this.prefs().classesShow);
            this.hide();
            if (!this.prefs().bodyOverflow) {
                enableScroll();
                this.ref('html').removeClass(this.prefs().classesGlobalOpened +
                    (this.prefs().classesGlobalExtra ? ' ' + this.prefs().classesGlobalExtra : ''));
            }
            this.cleanUpListener();
            this.ref(this.prefs().refContainer).empty();
            this.eventBus().emit('closeModal');
        }
    }
    cleanUpListener() {
        if (this.listener) {
            this.listener();
            this.listener = void 0;
        }
        if (this.escHandler) {
            this.escHandler();
        }
    }
    cancel() {
        this.closeModal();
        this.emit('cancel');
    }
}
