import { getContentByUrl as getContentByURL } from 'widgets/toolbox/ajax';
import { pushHistoryState, getUrlParams, removeParamFromURL, timeout } from 'widgets/toolbox/util';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import { scrollToTop, windowScrollTo, elementInViewport } from 'widgets/toolbox/scroll';
import viewtype from 'widgets/toolbox/viewtype';
import NavigationBar from '../global/NavigationBar';
import { withInfiniteScroll } from '../global/InfiniteScroll';
import { getGTMData } from '../../../../../../../int_gtm_w/cartridge/client/js/gtm/util.js';
import { RefElement } from 'widgets/toolbox/RefElement';
import { getCookie, setCookie } from 'widgets/toolbox/cookie';

const InfiniteScroll = withInfiniteScroll(NavigationBar);

/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class ProductListingMgr extends InfiniteScroll {
    prefs() {
        return {
            loadMoreBlock: 'loadMoreBlock',
            ...super.prefs()
        };
    }

    init() {
        super.init();

        this.isDesktopView = viewtype.isDesktopView();

        this.eventBus().on('viewtype.change', 'viewTypeChange');
        this.eventBus().on('refinement.section.opened', 'scrollToOpenedRefinement');
        this.eventBus().on('refinement.bar.close', 'closeNavBar');
        if (history.state && history.state.scroll) {
            window.scrollTo(0, history.state.scroll);
        }

        this.eventBus().emit('product.listing.init', this);

        this.defaultCategoryDescription = $('.l-search__top .l-search__container div.font-weight-light').text();

        this.updateCategoryDescription();
        this.updateFooterContent();
        this.runScript();
    }

    viewTypeChange() {
        this.isDesktopView = viewtype.isDesktopView();
    }

    scrollToOpenedRefinement(widget) {
        var widgetElement = widget.data('ref', 'self').get();
        if (this.isDesktopView) {
            if (!elementInViewport(widgetElement, true)) {
                windowScrollTo(widget.data('ref', 'self').offset().top);
            }
        } else if (!elementInViewport(widgetElement.parentElement, true)) {
            widgetElement.parentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }

    pushHistoryState(URL) {
        if (URL) {
            var clearURL = removeParamFromURL(URL, 'lang');
            clearURL = removeParamFromURL(clearURL, 'isAjax');
            pushHistoryState(clearURL);
        }
    }

    cleanUpContent() {
        this.ref('productGrid').empty();
        this.ref('productRefinements').empty();
    }

    /**
     * @param {string} url
     * @param {import('widgets/toolbox/RefElement').RefElement} [ref]
     * @param {string} [eventName] additional event to trigger after view update
     */
    updateByURL(url, ref, eventName) {
        this.showProgressBar();
        getContentByURL(url).then(response => {
            const doUpdate = response.trim().length > 0;
            if (doUpdate) {
                // Make ref-elements empty before rendering
                // to properly initialize replaced product tiles
                this.cleanUpContent();
                this.render(void 0, void 0, ref, response);
                this.pushHistoryState(url);
                this.config.pageNumber = 0;
                this.updateCannonicalUrl();
                this.updatePageTitle();
                this.updateFooterContent();
                this.eventBus().emit('product.listing.updated', this);
                // timeout to be sure that scrolling will happens
                // only after all needed manipulation with DOM
                timeout(() => {
                    this.scrollView();
                }, 0);
            }
            if (eventName) {
                this.eventBus().emit(eventName, doUpdate);
            }
        }).finally(() => {
            this.hideProgressBar();

            if (this.config.searchAttributes.filtersApplied) {
                this.eventBus().emit('spa.page.meta.data', {gtmData: getGTMData(), filtersApplied: this.config.searchAttributes.filtersApplied});
            }
        });
    }

    runScript () {
        const container = document.querySelector('.l-search__container');
        const targetElement = document.querySelector(
            '.l-search__container .h6'
        );
        if (targetElement) {
            container.classList.remove('l-search__hidden-content');
            const parentDiv = targetElement.parentElement;
            if (parentDiv) {
                Array.from(parentDiv.children).forEach((child) => {
                    const computedStyle = window.getComputedStyle(child);
                    if (computedStyle.textOverflow === 'ellipsis') {
                        const lineHeight = parseFloat(computedStyle.lineHeight);
                        const maxHeight = lineHeight * 2;
                        if (child.scrollHeight > maxHeight) {
                            const span = document.createElement('span');
                            span.innerHTML = child.innerHTML;
                            child.innerHTML = '';
                            child.appendChild(span);
                            const aTag = document.createElement('a');
                            aTag.className = 'plp_read_more';
                            aTag.setAttribute('data-widget', 'label');
                            aTag.setAttribute('data-event-click', 'handleReadMore');
                            aTag.textContent = 'Read more';
                            document.body.appendChild(aTag);
                            const readMoreWidth = 60;
                            document.body.removeChild(aTag);
                            const adjustedWidth = child.clientWidth - readMoreWidth;
                            aTag.style.left = adjustedWidth + 2 + 'px';
                            // Split the text content into two parts
                            const textContent = span.textContent;
                            const words = textContent.split(' ');
                            let firstLine = '',
                                secondLine = '';
                            span.textContent = '';
                            // Create a temporary span to measure text height
                            const tempSpan = document.createElement('span');
                            tempSpan.id = 'expandable-temp-plp';
                            child.appendChild(tempSpan);
                            for (let i = 0; i < words.length; i++) {
                                tempSpan.textContent += words[i] + ' ';
                                if (tempSpan.offsetHeight > lineHeight + 1) {
                                    secondLine = words.slice(i).join(' ');
                                    firstLine = words.slice(0, i).join(' ');
                                    break;
                                }
                            }
                            child.removeChild(tempSpan);
                            // Clear the span and set the first line
                            span.textContent = firstLine;
                            span.style.display = 'inline-block';
                            span.style.whiteSpace = 'nowrap';
                            // Create a second span for the second line
                            const secondSpan = document.createElement('span');
                            secondSpan.textContent = secondLine;
                            secondSpan.style.maxWidth = adjustedWidth + 'px';
                            secondSpan.id = 'expandable-plp';
                            span.appendChild(document.createElement('br'));
                            span.appendChild(secondSpan);
                            child.appendChild(aTag);
                        }
                    }
                });
            }
        }

        const quickLinks = document.querySelector('#quickLinksContainer');

        if (quickLinks && (typeof _uxa !== 'undefined')) {
            window._uxa.push(['setCustomVariable', 12, 'type', 'quicklinks']);
        }
    }

    updateView(button) {
        // prevent selection of current category
        if (button.data('selectedCategory')) {
            return;
        }

        // clear category id to reset search
        // to root instead of parent category
        // after removing category refinement
        var updateUrl = button.attr('data-href');
        if (button.data('clearCategory')) {
            updateUrl = removeParamFromURL(updateUrl, 'cgid');
        }

        this.updateByURL(updateUrl, new RefElement([window.document.querySelector('[data-widget="retailAI"]')]), button.attr('data-update-event'));

        const breadcrumbUrl = button.attr('data-breadcrumb-url');
        if (breadcrumbUrl) {
            this.updateByURL(breadcrumbUrl, new RefElement([window.document.querySelector('.b-breadcrumb.container')]));
        }
    }

    updateViewQuickLink(button) {
        var updateUrl = button.attr('data-href');

        var willBeSelected = !button.hasClass('b-refinement__quick-link--selected');
        var willDeselectNormalFilter = button.attr('data-normal-filter') === 'true';
        var quickLinkAttr = button.attr('data-quick-link-id');
        var isSingleSelection = button.attr('data-single-selection') === 'true';

        var currentQuickLinks = getCookie('quickLinks');
        var quickLinksArray = [];

        if (quickLinkAttr === 'all') {
            quickLinksArray = [];
        } else {
            if (currentQuickLinks) {
                quickLinksArray = decodeURI(currentQuickLinks).split('|');
            }

            if (!willDeselectNormalFilter) {
                if (willBeSelected && quickLinksArray.indexOf(quickLinkAttr) === -1) {
                    if (isSingleSelection) {
                        quickLinksArray = [quickLinkAttr];
                    } else {
                        quickLinksArray.push(quickLinkAttr);
                    }
                }

                if (!willBeSelected && quickLinksArray.indexOf(quickLinkAttr) !== -1) {
                    quickLinksArray = quickLinksArray.filter(function(element) {
                        return element !== quickLinkAttr;
                    });
                }
            }


        }

        setCookie('quickLinks', encodeURI(quickLinksArray.join('|')));

        this.updateByURL(updateUrl, new RefElement([window.document.querySelector('[data-widget="retailAI"]')]));
    }

    updateViewFromWidget(widget, button) {
        this.updateByURL(button.attr('data-href'), new RefElement([window.document.querySelector('[data-widget="retailAI"]')]), button.attr('data-update-event'));
    }

    /**
     * @param {import('widgets/forms/InputSelect').default} select
     */
    updateGrid(select) {
        this.updateByURL(select.getValue(), this.ref('productGrid'));
    }

    scrollView() {
        scrollToTop();
    }

    moreLoaded() {
        this.eventBus().emit('product.listing.updated', this, true);
    }

    showProgressBar() {
        showPageLoader();
    }

    hideProgressBar() {
        hidePageLoader();
    }

    isRootCategory(url) {
        var params = getUrlParams(url);
        return Object.keys(params).length && 'cgid' in params && params.cgid === 'root';
    }

    closeNavBar(_, event) {
        // do not close refinements bar if event was fired for price slider
        if (!event ||
            !event.detail ||
            !event.detail.target ||
            !event.detail.target.className ||
            event.detail.target.className.indexOf('noUi-') === -1) {
            super.closeNavBar();
        }
    }

    updatePageTitle() {
        if ($('input[name="pageTitle"').length > 0) {
            var newTitle = $('input[name="pageTitle"')[0].value;
            document.title = newTitle;
            $('meta[property="og:title"]').attr('content', newTitle);
            var pageHeader = $('input[name="plpDynamicH1Data"')[0].value;
            $('.l-search__top .h6').text(pageHeader);
        }
        var pageSlotDescription = $('.plpSlotDynamicDescription .l-search__topdescription div').text();
        var pageDescription = pageSlotDescription ? pageSlotDescription : $('input[name="plpDynamicDescription"')[0].value;
        if (pageDescription !== 'null') {
            $('.l-search__top .l-search__container div.font-weight-light').html(pageDescription);
            $('.l-search__top .l-search__container div.font-weight-light').show();
            this.updateCategoryDescription();
        } else {
            $('.l-search__top .l-search__container div.font-weight-light').hide();
        }
        this.runScript();
    }
    updateCannonicalUrl() {
        if ($('input[name="cannonicalURL"').length > 0) {
            var newCannonicalUrl = $('input[name="cannonicalURL"')[0].value;
            $('link[rel="canonical"]')[0].href = newCannonicalUrl;
        }
    }

    updateCategoryDescription() {
        const setMetaDescription = (content) => {
            if ($('meta[property="og:description"]').length == 0) {
                const metaDescription = document.createElement('meta');
                metaDescription.setAttribute('property', 'og:description');
                metaDescription.setAttribute('content', content);
                $(metaDescription).insertAfter('meta[property="og:title"]');
            } else {
                $('meta[property="og:description"]').attr('content', content);
            }
        };
        var pageSlotDescription = $('.plpSlotDynamicDescription').text();
        var pageDescription = pageSlotDescription ? pageSlotDescription : $('input[name="plpDynamicDescription"')[0].value;
        if (pageDescription !== 'null') {
            setMetaDescription(pageDescription);
        }

    }

    updateFooterContent() {
        $('.l-search__bottom').empty();
        const url = this.data('getcontent-url');
        let cid = $('.l-search__top .h6').text().trim().toLocaleLowerCase();
        cid += '-bottom-plp-text';
        cid = cid.split(' ').join('-');
        fetch(url + '?cid=' + cid).then(res => res.json()).then(data => {
            if (data.success) {
                $('.l-search__bottom').html(data.body);
            }
        });
    }
}
