import viewtype from 'widgets/toolbox/viewtype';
import { get } from 'widgets/toolbox/util';
import { elementInViewport } from 'widgets/toolbox/scroll';

const Constants = window.Constants;

export function report(...args) {
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(...args);
    }
}

export function reportPromoView(promoList) {
    const dataObject = {
        event: 'view_promotion',
        _clear: true,
        ecommerce: {
            promoView: {
                promotions: promoList
            }
        },
        items: promoList.map((promoObject) => {
            return {
                promotion_id: promoObject.id,
                promotion_name: promoObject.name,
                creative_name: promoObject.creative,
                creative_slot: promoObject.position
            };
        })
    };
    report(dataObject);
}

export function reportPromoClick(promoObject) {
    const dataObject = {
        event: 'select_promotion',
        _clear: true,
        ecommerce: {
            promoView: {
                promotions: [promoObject]
            }
        },
        items: [{
            promotion_id: promoObject.id,
            promotion_name: promoObject.name,
            creative_name: promoObject.creative,
            creative_slot: promoObject.position
        }]
    };

    report(dataObject);
}

export function getListFromHash() {
    const hash = window.location.hash;
    const list = hash.substring(1).split('#').map(hashParam => {
        var hashParamArray = hashParam.split('=');
        if (hashParamArray.length > 1 && hashParamArray[0] === 'list') {
            return hashParamArray[1];
        }
    }).join('');

    if (list) {
        window.location.hash = hash.replace('#list=' + list, '');
        if (!window.location.hash) {
            history.pushState('', document.title, window.location.pathname + window.location.search);
        }
    }

    return decodeURI(list);
}

export function getDeviceType() {
    return viewtype.getViewType();
}

export function getGTMData() {
    var gtmData = {
        ...window.gtmData || {},
        ...window.gtmPageData || {}
    };

    gtmData.language = (gtmData.locale || '').split('_')[0].toUpperCase();
    if (gtmData.language === 'DEFAULT') {
        gtmData.language = 'EN';
    }
    gtmData.site = window.SitePreferences.IS_GLOBAL_SITE ? 'International' : 'UK';

    gtmData.deviceType = getDeviceType();
    gtmData.visitorType = gtmData.customer.loggedin === 'TRUE' ? 'loggedin' : 'guest';

    gtmData.clickedList = getListFromHash();

    window.gtmData = gtmData;

    return gtmData;
}

export function getCartItemByUUID(cart, uuid) {
    for (let i = 0; i < cart.items.length; i++) {
        if (cart.items[i].UUID === uuid) {
            return cart.items[i];
        }
    }
    return null;
}

export function transformProductAnalyticsToEcommerce(productAnalytics, transformFn = ((x) => x)) {
    return productAnalytics.map(item => transformFn(item)).map((item) => ({
        brand: item.brand,
        category: item.category,
        dimension24: item.color,
        currency: item.currency, // TODO check
        dimension26: item.variantSku,
        dimension13: item.displayType,
        id: item.variationGroupSku,
        list: '',
        name: item.name,
        position: 0,
        price: item.price,
        quantity: item.quantity,
        dimension25: item.size,
        dimension12: item.length,
        variant: item.variant,
        dimension46: item.variantSku,
        coupon: item.coupon,
        metric11: item.netPrice,
        dimension27: item.inventory,
        dimension47: item.addToCartType
    }));
}

export function transformProductAnalyticsToItems(productAnalytics, transformFn = ((x) => x), itemListName) {
    return productAnalytics.map(item => transformFn(item)).map((item) => {
        var itemObj = {
            item_id: item.variationGroupSku,
            item_variant_id: item.variantSku,
            item_name: item.name,
            item_brand: item.brand,
            item_category: item.category,
            item_variant: item.variant,
            price: item.price,
            discount: (item.discount || 0).toFixed(2),
            discountedNetPrice: item.discountedNetPrice ? item.discountedNetPrice.toFixed(2) : undefined,
            quantity: item.quantity,
            coupon: item.coupon
        };

        if (itemListName) {
            itemObj = { ...itemObj, item_list_name: itemListName };
        }

        return {
            itemObj
        };
    });
}

export function getListNameFromWidget(widget, quickviewList) {
    var impressionList = '';

    const widgetListItem = widget.get().parentElement;
    const parentWidgetElement = widgetListItem.closest('[data-widget]');

    if (parentWidgetElement) {
        const parentWidget = parentWidgetElement['@@_widget_instance_@@'];

        if (parentWidget) {
            impressionList = (parentWidget.data('title') || '').trim();
            parentWidget.has('title', title => impressionList = (title.getText() || '').trim());
            if (!impressionList) {
                if (quickviewList && parentWidget.config.widget === 'modal') {
                    impressionList = quickviewList;
                } else if (parentWidget.config.widget === 'cartMgr') {
                    impressionList = Constants.CHECKOUT;
                } else if (parentWidget.config.widget === 'minicart') {
                    impressionList = Constants.MINI_CART;
                }
            }
            return impressionList;
        }
    } else if (widget.config.widget === 'inputNumber') {
        impressionList = Constants.CART;
    }

    return impressionList;
}

export function getImpressionPosition(widget) {
    var widgetItem;

    if (Array.isArray(widget)) {
        widgetItem = widget[1].get();
    } else {
        widgetItem = widget.get();
    }

    var widgetListItem = widgetItem.parentElement;

    if (widgetListItem) {
        return Array.prototype.slice.call(widgetListItem.parentElement.getElementsByClassName(widgetListItem.className)).indexOf(widgetListItem) + 1;
    }
}


export function getAnalyticsData(widget) {
    var analyticsData = widget.analytics || widget.data('analytics');

    if (!analyticsData) {
        widget.data('has', 'analytics', analytics => analyticsData = analytics.data('analytics'));
    }

    if (analyticsData) {
        if (typeof analyticsData === 'string') {
            analyticsData = JSON.parse(analyticsData);
        }

        if (!Array.isArray(analyticsData)) {
            analyticsData = [analyticsData];
        }
    }
    return analyticsData;
}

function constructPromoObject(promoElement) {
    if (promoElement) {
        const promoObject = { id: '', name: '', creative: '', position: '' };
        let promoData;
        try {
            promoData = JSON.parse(promoElement.dataset.promoview);
            let promoIdValue = promoData ? promoData.promoID : '';
            let promoNameValue = promoData ? promoData.promoName : '';
            if (!promoIdValue || promoIdValue === '') {
                const promoIdElement = promoElement ? promoElement.querySelector('.b-banner__link') : null;
                promoIdValue = promoIdElement ? promoIdElement.innerText.trim() : '';
            }

            if (!promoNameValue || promoNameValue === '') {
                const promoNameElement = promoElement ? promoElement.querySelector('.b-banner__content') : null;
                promoNameValue = promoNameElement ? promoNameElement.innerText.replace(/\n/g, '').trim() : '';
            }
            promoObject.id = promoIdValue;
            promoObject.name = promoNameValue;
            promoObject.creative = promoData ? promoData.assetID : '';
            promoObject.position = promoData ? promoData.slotID : '';

        } catch (err) {
            window.console.error('Parsing promo info error: ' + err);
            return promoObject;
        }

        return promoObject;
    }
}

export function getPromoList() {
    const bannerList = document.querySelectorAll('[data-widget="banner"]');
    const wrapperClassName = '.js-banner-wrapper:not(.promo-reported)';
    const promoList = [];

    for (var i = 0; i < bannerList.length; i++) {
        const wrapper = bannerList[i].closest(wrapperClassName);
        if (wrapper && elementInViewport(wrapper)) {
            wrapper.classList.add('promo-reported');
            const promoObject = constructPromoObject(wrapper);
            if (promoObject) {
                promoList.push(promoObject);
            }
        }
    }
    return promoList;
}

export function getPromoObject(bannerElement) {
    const wrapperClassName = '.js-banner-wrapper';
    const promoWrapper = bannerElement.closest(wrapperClassName);
    const promoObject = constructPromoObject(promoWrapper);
    return promoObject;
}

export function getPaymentNameByID(methodId) {
    return Constants.PAYMENT_METHODS[methodId] || methodId;
}

export function getOrderPayment(order) {
    var paymentName = get(order, 'analytics.paymentMethod');
    if (!paymentName) {
        paymentName = getPaymentNameByID(get(order, 'billing.payment.selectedPaymentInstruments.0.paymentMethod'));
    }

    return paymentName || '';
}

export function getOrderAffiliation() {
    const isDesktopView = viewtype.isDesktopView();
    if (window.gtmData.customer.loggedin === 'TRUE') {
        return isDesktopView ? 'web' : 'mobile';
    }
    return isDesktopView ? 'guest' : 'mobile_guest';
}

export function getCheckoutType(isLogin) {
    return isLogin ? Constants.LOGIN : Constants.GUEST_CHECKOUT;
}

export function getDeliveryType(shipping, isGlobalEPurchase) {
    if (isGlobalEPurchase) {
        return shipping ? Constants.CLICK_AND_COLLECT : Constants.DELIVERY;
    }

    return (shipping && shipping.length && shipping[0].clickAndCollectData)
        ? Constants.CLICK_AND_COLLECT : Constants.DELIVERY;
}

export function getDeliveryMethod(shipping, isGlobalEPurchase) {
    var method = (shipping && shipping.length && shipping[0].selectedShippingMethod) || shipping;

    if (isGlobalEPurchase) {
        return method;
    }

    return method && ((method.ID && (method.ID.toLowerCase().indexOf(Constants.STANDARD) > -1 ||
        method.displayName.toLowerCase().indexOf(Constants.STANDARD) > -1))) ? Constants.STANDARD : Constants.PREMIUM;
}

export function getAddToCartType(productList, impression) {
    const TYPES = Constants.TYPES_PAGES;
    var type = Constants.TYPE_PDP;
    if (productList) {
        if (TYPES[productList]) {
            type = TYPES[productList];
        } else if (impression.category.indexOf(productList) > -1) {
            type = Constants.TYPE_PLP;
        } else {
            type = Constants.TYPE_CROSS_SELL;
        }
    }
    return type;
}
